import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "RubyKaigi 2019 に朝食スポンサーとして協賛します",
  "date": "2019-04-12T09:40:55.000Z",
  "slug": "entry/2019/04/12/184055",
  "tags": ["medley"],
  "hero": "./2019_04_12.png",
  "heroAlt": "ruby kaigi sponsor"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`みなさん、こんにちは。開発本部の平木です。`}</p>
    <p>{`2017 年から、RubyKaigi にスポンサーとして参加していましたが、今年も`}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2019/sponsors"
      }}>{`朝食スポンサー`}</a>{`として協賛することになりました!`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190412/20190412183606.png",
      "alt": "20190412183606.png"
    }}></img>
    <p>{`(去年とおととしの参加レポート)`}</p>
    <p><a parentName="p" {...{
        "href": "/entry/2017/09/28/120000"
      }}>{`RubyKaigi 2017 にメドレーが Ruby Sponsor として参加しました`}</a></p>
    <p><a parentName="p" {...{
        "href": "/entry/2018/06/06/151300"
      }}>{`Lightning Talks Sponsor として RubyKaigi 2018 に参加してきました`}</a></p>
    <p>{`会場である、福岡国際会議場内の 1F にあるレストラン `}<a parentName="p" {...{
        "href": "https://www.f-sunpalace.com/restaurant/raconter/#raconter%E2%80%8B"
      }}>{`ラコンテ`}</a>{`さんでビュッフェ形式の朝食を楽しむことができます。`}</p>
    <p>{`会期中の 4/19~20 日の 8:30 ~ 10:00 で開催しています。当日はメドレーメンバーがご案内する予定になっていますので、ぜひお気軽に話かけてください!目印として、メンバーはメドレーパーカーを着用しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190412/20190412183045.jpg",
      "alt": "20190412183045.jpg"
    }}></img>
    <p>{`メニューは和洋それぞれ用意があり、福岡ならではのメニューも入っていますので、1 日の始まりにぜひおいしい朝食を食べて元気にセッションに臨んでください。`}</p>
    <p>{`また、スポンサーブース内にもメドレーブースが常設されており、エンジニア 4 人も行く予定となっていますので、ぜひお立ち寄りいただいて、みなさんと交流できればと思います。`}</p>
    <p>{`それでは、RubyKaigi という大きなイベントでまた皆様にお会いできるのを楽しみにしています!!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      